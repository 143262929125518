@blue: #1C4C7A;
@darkblue: #001B34;
@lightblue: #55ACEE;
@black: #000;
@lightblack: #252525;
@white: #FFF;
@grey: #7A8084;
.transition() {
	-webkit-transition: all .3s ease;
	-moz-transition:    all .3s ease;
	-o-transition:      all .3s ease;
	transition:         all .3s ease;
}
.alignblock {
	display: block;
	margin: 0 auto;
}
body {
	background: url('../img/background.jpg') no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: @white;
}
#fp-nav {
	ul {
		li {
			a {
				span {
					background: @white;
				}
			}
		}
	}
}
.btn-transparent {
	background: rgba(255, 255, 255, 0);
	border: 1px white solid;
	&:hover {
		color: white;
		background: @lightblue;
	}
}
.story-section {
	p {
		margin-bottom: 20px;
	}
}
.scroll-page {
	width: 40px;
	height: 40px;
	background-size: cover;
	position: absolute;
	left: 50%;
	opacity: .8;
	.transition();
	&:hover {
		.transition();
		opacity: 1;
	}
}
.scroll-down-small {
	background: url('../img/scroll-down.png') no-repeat center center;
	bottom: 1%;
}
.scroll-up {
	background: url('../img/scroll-up.png') no-repeat center center;
	top: 5%;
}
.scroll-down {
	background: url('../img/scroll-down.png') no-repeat center center;
	bottom: 5%;
}
p {
	margin-bottom: 5px;
}
.waveform {
	margin-bottom: 40px;
	.band {
	    background-color: #55acee;
	    border-radius:0;
	    .transition();
	}
}
.logo {
	img {
		display: block;
		margin: 0 auto;
	}
}
.schedule-row {
	border-bottom: 1px solid @grey;
	margin: 40px 0;
	font-weight: 300;
	h4 {
		margin-top: 0;
	}
	.col-md-6 {
		padding: 0;
	}
	.schedule-wrapper {
		float: left;
		position: relative;
		padding: 0 15px;
	}
	.schedule-time {
		font-size: 28px;
		color: @grey;
	}
	.schedule-name {
		font-size: 18px;
	}
	.schedule-speaker {
		font-size: 12px;
		color: @grey;
	}
}
.player {
	width: 100%;
	.audio-control {
		border: 0;
		background: transparent;
		font-size: 36px;
		font-weight: 300;
		&:hover {
			color: @lightblue;
		}
	}
	.audio-wrapper {
		float: left;
		position: relative;
		width: 15%;
		padding: 0 10px;
	}
	.progress-wrapper {
		float: left;
		position: relative;
		width: 65%;
		padding: 0 10px;
	}
	.volume-wrapper {
		float: left;
		position: relative;
		width: 20%;
		padding: 0 10px;
	}
	.play {
		.alignblock();
	}
	.progressout {
	    height: 2px;
	    border: 1px solid @white;
	    position: relative;
	    border-radius: 10px;
	    border-width: 0;
	    margin-top: 25px;
		.progressin {
		    border-radius: 10px;
		    position: relative;
		    height: 100%;
		    width: 100%;
		    bottom: 0px;
		    background: @white;
			.time {
			    position: absolute;
			    padding: 5px 8px;
			    font-size: 10px;
			    background: #12BFD3;
			    right: 0px;
			    bottom: 0px;
			    border-radius: 10px;
			}
		}
	}
	.volume {
		border: none;
		border-radius: 10px;
		height: 5px;
		background: hsla(180,75%,50%,1);
		margin-top: 22px;
	}
	.ui-slider-handle {
		border-radius: 50%;
		top: -3px !important;
		width: 11px !important;
		height: 11px !important;
		margin-left: -5px !important;
	}
}

// @media only screen and (max-width : 1367px) {
// 	.scroll-down-small {
// 		bottom: 10px;
// 	}
// 	.scroll-up {
// 		top: -100px;
// 	}
// 	.scroll-down {
// 		bottom: -100px;
// 	}
// }
@media only screen and (max-width : 768px) {
	.scroll-page {
		display: none;
	}
}
